/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "@fontsource/shrikhand/400.css"
import "@fontsource-variable/jetbrains-mono"
import '@fontsource-variable/noto-sans-jp';

 // You can delete this file if you're not using it
export const onRouteUpdate = ({ location }) => {

}